/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import useResizeObserver from 'use-resize-observer';

import Icon from '@common/components/Icon';
import Button from '@common/components/Button';
import IconButton from '@common/components/IconButton';
import IconSwitch from '@common/components/IconSwitch';
import Pill from '@common/components/Pill';
import IconHover from '@common/components/IconHover';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';

import { SessionResource } from '@common/transforms/session';
import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';
import { renderLocationIcon } from '@modules/event/utils';

interface SessionListProps {
  session: SessionResource;
  onNotifyClick: (enabled: boolean) => void;
  onScheduleClick: () => void;
  onRemoveScheduleClick: () => void;
  onJoinClick: () => void;
  sessionStatus: SESSION_STATES;
  selectedDate?: Date;
}

const SessionList: React.FC<SessionListProps> = ({
  onNotifyClick,
  onScheduleClick,
  onRemoveScheduleClick,
  onJoinClick,
  sessionStatus,
  session,
  selectedDate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { ref: container, width = 0 } = useResizeObserver<HTMLDivElement>();
  const { duration, buttons } = useSessionSettings(session, sessionStatus, selectedDate);

  return (
    <Wrapper sessionStatus={sessionStatus} session={session} ref={container}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 40%;
          max-width: 40%;
          flex-grow: 0;
          flex-shrink: 0;
        `}
      >
        {!!session.imageUrl?.length && <Banner src={session.imageUrl} alt='banner' width={width} />}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            width: 100%;
            padding-right: 1em;
          `}
        >
          <Title>{session.title}</Title>

          {!!session.speakers && session.speakers.length > 0 && (
            <Speakers>
              {session.speakers.length > 0 &&
                session.speakers
                  .map<React.ReactNode>((speaker) => <span key={speaker.id}>{speaker.name}</span>)
                  .reduce((prev, curr) => [prev, ', ', curr])}
            </Speakers>
          )}
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 60%;
          min-width: 0;
        `}
      >
        <Left>
          {buttons.showJoin && (
            <>
              <span
                css={css`
                  margin-right: 1.5em;
                `}
              >
                {renderLocationIcon(session.location) === 'both' ? (
                  <>
                    <ButtonWithHoverLabel
                      labelText={t('attendance_location.onsite')}
                      detailed
                      small
                    >
                      <IconHover icon='pinpoint' size={18} />
                    </ButtonWithHoverLabel>
                    <ButtonWithHoverLabel
                      labelText={t('attendance_location.online')}
                      detailed
                      small
                    >
                      <IconHover icon='laptop' size={18} />
                    </ButtonWithHoverLabel>
                  </>
                ) : (
                  <ButtonWithHoverLabel
                    labelText={t(`attendance_location.${session.location.toLowerCase()}`)}
                    detailed
                    small
                  >
                    <IconHover icon={renderLocationIcon(session.location)} size={18} />
                  </ButtonWithHoverLabel>
                )}
              </span>
              <Pill highlighted icon='live'>
                {t('live.now')}
              </Pill>
              {!!session.stage?.id && <Pill>{session.stage.name}</Pill>}
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  align-self: center;
                  flex: 1;
                `}
              >
                <Button
                  onClick={() => onJoinClick()}
                  color='secondary'
                  label={t('joinSession')}
                  disableMinWidth
                  size='m'
                />
              </div>
            </>
          )}
          {sessionStatus !== SESSION_STATES.LIVE && (
            <Time>
              <Icon icon='clock' color={theme.colors.textLight} size={16} />
              <span
                css={css`
                  margin-left: 5px;
                `}
              >
                {duration.timeslot}
              </span>
            </Time>
          )}
          {!!session.stage?.id && sessionStatus === SESSION_STATES.NOT_STARTED && (
            <Pill>{session.stage.name}</Pill>
          )}
          {buttons.showOndemand && (
            <Pill
              css={css`
                flex-shrink: 0;
              `}
            >
              {t('onDemand')}
            </Pill>
          )}
          {buttons.showFinished && (
            <Pill
              css={css`
                flex-shrink: 0;
              `}
            >
              {t('finished')}
            </Pill>
          )}
        </Left>
        {sessionStatus !== SESSION_STATES.PASSED && (
          <Right>
            {buttons.showReminder && (
              <ButtonWithHoverLabel reminder>
                <IconSwitch
                  onChange={onNotifyClick}
                  selected={session.reminder}
                  icon='bell'
                  size={40}
                />
              </ButtonWithHoverLabel>
            )}
            <div
              css={
                buttons.showMandatory || buttons.showAddToSchedule || buttons.showRemoveFromSchedule
                  ? css`
                      margin-left: 0.75em;
                    `
                  : undefined
              }
            >
              {buttons.showMandatory && (
                <ButtonWithHoverLabel mandatory>
                  <IconButton
                    disabled
                    type='scheduled'
                    icon='mandatory'
                    hoverIcon='mandatory'
                    size={40}
                  />
                </ButtonWithHoverLabel>
              )}
              {buttons.showRemoveFromSchedule && (
                <ButtonWithHoverLabel labelText={t('removeFromSchedule')} marginLeft>
                  <IconButton
                    type='scheduled'
                    icon='checkmark'
                    hoverIcon='times'
                    size={40}
                    onClick={onRemoveScheduleClick}
                  />
                </ButtonWithHoverLabel>
              )}
              {buttons.showAddToSchedule && (
                <ButtonWithHoverLabel labelText={t('addToMySchedule')} marginLeft>
                  <IconButton onClick={() => onScheduleClick()} icon='plus' size={40} />
                </ButtonWithHoverLabel>
              )}
            </div>
          </Right>
        )}
      </div>
    </Wrapper>
  );
};

export default SessionList;

const Wrapper = styled.div<{ sessionStatus: SESSION_STATES; session: SessionResource }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  padding: 1em;
  margin-bottom: 1em;
  position: relative;
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});
  background-color: ${(props) =>
    props.session.scheduled && props.sessionStatus !== SESSION_STATES.LIVE
      ? 'rgba(80, 201, 127, 0.16)'
      : 'rgba(255, 255, 255, 0.1)'};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  ${(props) =>
    props.sessionStatus !== SESSION_STATES.PASSED &&
    `
    :hover {
      background-color: ${
        props.session.scheduled && props.sessionStatus !== SESSION_STATES.LIVE
          ? 'rgba(80, 201, 127, 0.3)'
          : 'rgba(255, 255, 255, 0.15)'
      };
    }
  `}
`;

const Banner = styled.img<{ width: number }>`
  display: ${(props) => (props.width < 700 ? 'none' : 'block')};
  object-fit: cover;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  width: 64px;
  height: 64px;
  flex: none;
  margin-right: 1em;
`;

const Title = styled.div`
  display: -webkit-box;
  max-height: 115px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontSizes.h3};
  color: ${(props) => props.theme.colors.textLight};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Speakers = styled.div`
  color: ${(props) => props.theme.colors.textBody};
  font-size: ${(props) => props.theme.fontSizes.s};
  margin-top: 0.25em;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: 1;
  min-width: 0;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
  min-width: 0;
`;

const Time = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1em;
`;
