/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { readableColor } from 'polished';
import stc from 'string-to-color';
import { useTranslation } from 'react-i18next';

import { SpeakerResource } from '@common/transforms/speaker';
import { CallStatus, UserResource } from '@common/transforms/user';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import Card from '@common/components/Card';
import IconButton from '@common/components/IconButton';
import IconSwitch from '@common/components/IconSwitch';
import Chip from '@common/components/Chip';
import { CallStatusChip } from '@modules/video-call/components/CallStatusChip';
import { getUserId } from '@modules/auth/hooks/useAuth';
import { useAppSelector } from '@common/store';
import IconHover from '@common/components/IconHover';
import useEvent from '@modules/event/hooks/useEvent';
import { renderLocationIcon } from '@modules/event/utils';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import { UserRolePermission } from '@common/transforms/userRole';

interface BaseCardProps {
  className?: string;
  showCustomFields?: boolean;
  showCallStatus?: boolean;
  onOpenChat?: (participantId: string) => void;
  onCallUser?: (participantId: string) => void;
  onOpenLinkedIn?: () => void;
  onOpenVideoLink?: () => void;
  onClick?: () => void;
  invite?: boolean;
  selected?: boolean;
  inviteClick?: () => void;
  view?: 'vertical' | 'horizontal';
}

interface UserCardProps extends BaseCardProps {
  user: UserResource;
  speaker?: never;
}

interface SpeakerCardProps extends BaseCardProps {
  speaker: SpeakerResource;
  user?: never;
}

const UserCard: React.FC<UserCardProps | SpeakerCardProps> = ({
  className,
  showCustomFields = false,
  showCallStatus = false,
  onOpenChat,
  onCallUser,
  onOpenLinkedIn,
  onOpenVideoLink,
  onClick,
  invite,
  selected,
  inviteClick,
  user,
  speaker,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const event = useEvent();
  const currentUser = useAppSelector((state) => state.auth.user);
  const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
  const sidebarItem = useAppSelector((state) => state.sidebar.activeItem);
  const role = speaker ? speaker.user?.role : user?.role;
  const initialColor = stc(role || 'random');

  const usr = speaker ? speaker?.user : user;

  return (
    <Card
      onClick={onClick}
      className={className}
      css={css`
        flex-direction: row;
        padding: 1em;
        align-items: start;
        cursor: pointer;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: top;
          justify-content: center;
        `}
      >
        <Avatar
          user={user}
          speaker={speaker}
          size={mobileFullscreen && sidebarItem ? 40 : 56}
          emptyBackground
        />
        {user?.isOnline && (
          <div
            css={css`
              position: absolute;
              margin-top: 39px;
              margin-left: 36px;
              background-color: lightgreen;
              width: 10pt;
              height: 10pt;
              border-radius: 10pt;
              border-width: 2pt;
              border-style: solid;
              border-color: ${theme.colors.eventBackground};
            `}
          />
        )}
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          ${mobileFullscreen && sidebarItem ? 'margin-left: 1em;' : 'margin-left: 1.5em;'}
        `}
      >
        <Body weight='bold' color='textLight'>
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            `}
          >
            <span
              css={css`
                margin-right: 0.5em;
              `}
            >
              {speaker ? speaker.name : user?.fullName || user?.firstName}
            </span>
            {event?.attendanceEnabled && usr ? (
              <span
                css={css`
                  margin-right: 0.5em;
                `}
              >
                <ButtonWithHoverLabel
                  labelText={t(`attendance_location.${usr.attendance.toLowerCase()}`)}
                  small
                >
                  <IconHover size={16} icon={renderLocationIcon(usr.attendance)} />
                </ButtonWithHoverLabel>
              </span>
            ) : null}
            {!speaker && role?.visible && (
              <Chip
                size='xxs'
                css={css`
                  margin-top: 0.2em;
                  background-color: ${initialColor};
                  color: ${readableColor(initialColor)};
                `}
                className='chip'
              >
                {role.name}
              </Chip>
            )}
          </div>
        </Body>
        <Body color='primary' size='s'>
          {speaker ? speaker.jobTitle : user?.function}
        </Body>
        {speaker && (
          <Body color='primary' size='s'>
            {speaker ? speaker.companyName : user?.function}
          </Body>
        )}
        {(showCustomFields || onOpenChat || onCallUser) && (
          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin-top: 0.5em;
              > * {
                margin-right: 0.5em;
              }
            `}
          >
            {onOpenChat &&
              !!usr &&
              (((usr.role?.permissions ?? []).includes(UserRolePermission.CREATE_PRIVATE_CHAT) &&
                (currentUser?.role?.permissions ?? []).includes(
                  UserRolePermission.CREATE_PRIVATE_CHAT,
                )) ||
                (usr.role?.permissions ?? []).includes(UserRolePermission.HOST_CHAT)) && (
                <IconButton
                  onClick={() => onOpenChat(usr.id)}
                  icon='chat'
                  size={30}
                  iconProps={{
                    size: 14,
                  }}
                  round
                />
              )}
            {!!onCallUser &&
              !!usr &&
              usr?.id !== getUserId() &&
              (usr.role?.permissions ?? []).includes(
                UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS,
              ) &&
              (currentUser?.role?.permissions ?? []).includes(
                UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS,
              ) && (
                <IconButton
                  onClick={() => onCallUser(usr.id)}
                  icon='phone'
                  size={30}
                  disabled={!usr.isOnline || usr.callStatus !== CallStatus.AVAILABLE}
                  iconProps={{
                    size: 14,
                  }}
                  round
                />
              )}
            {usr?.linkedIn && (
              <IconButton
                onClick={onOpenLinkedIn}
                icon='linkedin'
                size={30}
                iconProps={{
                  size: 14,
                }}
                round
              />
            )}
            {usr?.videoLink && usr?.id !== getUserId() && (
              <IconButton
                onClick={onOpenVideoLink}
                icon='video-call'
                size={30}
                iconProps={{
                  size: 14,
                }}
                round
              />
            )}
          </div>
        )}
      </div>
      {showCallStatus && !!usr && usr.callStatus !== CallStatus.AVAILABLE && (
        <CallStatusChip
          css={css`
            margin-left: 1.5em;
          `}
          status={usr?.callStatus as CallStatus}
        />
      )}
      {invite &&
        inviteClick &&
        !!usr &&
        usr?.id !== getUserId() &&
        usr?.callStatus === CallStatus.AVAILABLE && (
          <IconSwitch
            onChange={inviteClick}
            icon={selected ? 'minus' : 'plus'}
            size={35}
            selected={selected}
            round
          />
        )}
    </Card>
  );
};

export default UserCard;
