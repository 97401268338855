/** @jsxImportSource @emotion/react */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, css } from '@emotion/react';
import dayjs from 'dayjs';

import i18n from '@common/utils/i18n';
import { displayDate, parseISO } from '@common/utils/time';
import { SessionResource } from '@common/transforms/session';
import { renderLocationIcon } from '@modules/event/utils';
import { SESSION_STATES } from '@modules/schedule/hooks/useSessionStatus';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';

import Pill from '@common/components/Pill';
import Button from '@common/components/Button';
import AttendeesIndicator from '@modules/user/components/AttendeesIndicator';
import IconButton from '@common/components/IconButton';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import IconSwitch from '@common/components/IconSwitch';
import IconHover from '@common/components/IconHover';

interface SessionDetailedProps {
  session: SessionResource;
  onNotifyClick: (enabled: boolean) => void;
  onScheduleClick: () => void;
  onRemoveScheduleClick: () => void;
  onWatchClick: () => void;
  onJoinClick: () => void;
  sessionStatus: SESSION_STATES;
}

const SessionDetailed: React.FC<SessionDetailedProps> = ({
  onNotifyClick,
  onJoinClick,
  onScheduleClick,
  onRemoveScheduleClick,
  onWatchClick,
  sessionStatus,
  session,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { duration, buttons } = useSessionSettings(session, sessionStatus, new Date());

  const [scheduleHover, setScheduleHover] = useState(false);

  const Section: React.FC<{ className?: string; hideBorder?: boolean }> = ({
    className,
    hideBorder,
    children,
  }) => (
    <div
      className={className}
      css={css`
        padding: 25px 20px;
        ${!hideBorder &&
        `
          border-top: 1px solid ${theme.colors.border};
        `}
      `}
    >
      {children}
    </div>
  );

  const startDate = () => {
    let sessionDate = session.startsAt;
    if (dayjs().isBetween(dayjs(session.startsAt), dayjs(session.endsAt))) {
      sessionDate = dayjs().format();
    }
    return displayDate(parseISO(sessionDate)) === 'Today'
      ? t('today')
      : parseISO(sessionDate).toLocaleDateString(i18n.language.replace('_', '-'), {
          day: 'numeric',
          month: 'long',
        });
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        border-radius: ${theme.borderRadius.normal};
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${session.scheduled && sessionStatus !== SESSION_STATES.LIVE
          ? 'rgba(80, 201, 127, 0.16)'
          : 'rgba(255, 255, 255, 0.1)'};
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        ${sessionStatus !== SESSION_STATES.PASSED &&
        `
          :hover {
            background-color: ${
              session.scheduled && sessionStatus !== SESSION_STATES.LIVE
                ? 'rgba(80, 201, 127, 0.3)'
                : 'rgba(255, 255, 255, 0.15)'
            };
          }
        `}
      `}
    >
      <div
        css={css`
          overflow: hidden;
          position: relative;
          width: 100%;
          height: ${session.imageUrl?.length ? '216px' : 'auto'};
        `}
      >
        {!!session.imageUrl?.length && (
          <img
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={session.imageUrl as string}
            alt='Banner'
          />
        )}
        <div
          css={css`
            ${!!session.imageUrl?.length &&
            `
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
            `}
            padding: 20px;
            display: flex;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            {buttons.showLive && (
              <Pill
                highlighted
                icon='live'
                css={css`
                  flex-shrink: 0;
                `}
              >
                {t('live.now')}
              </Pill>
            )}
            {buttons.showOndemand && (
              <Pill
                css={css`
                  flex-shrink: 0;
                `}
              >
                {t('onDemand')}
              </Pill>
            )}
            {buttons.showFinished && (
              <Pill
                css={css`
                  flex-shrink: 0;
                `}
              >
                {t('finished')}
              </Pill>
            )}
            {!!session.stage?.id && sessionStatus !== SESSION_STATES.PASSED && (
              <Pill>{session.stage.name}</Pill>
            )}
          </div>
        </div>
      </div>

      <Section
        css={css`
          flex: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 100px;
            font-size: ${theme.fontSizes.m};
            color: ${theme.colors.textLight};
          `}
        >
          <span
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              border-radius: 100px;
              margin-bottom: 12px;
              font-size: ${theme.fontSizes.s};
              color: ${theme.colors.textLight};
            `}
          >
            {duration.timeslot}{' '}
            <span
              css={css`
                margin: 0px 6px;
              `}
            >
              •
            </span>
            <span>{startDate()}</span>
            <span
              css={css`
                margin: 0px 6px;
              `}
            >
              •
            </span>
            {renderLocationIcon(session.location) === 'both' ? (
              <>
                <ButtonWithHoverLabel labelText={t('attendance_location.onsite')} detailed small>
                  <IconHover icon='pinpoint' size={18} />
                </ButtonWithHoverLabel>
                <ButtonWithHoverLabel labelText={t('attendance_location.online')} detailed small>
                  <IconHover icon='laptop' size={18} />
                </ButtonWithHoverLabel>
              </>
            ) : (
              <ButtonWithHoverLabel
                labelText={t(`attendance_location.${session.location.toLowerCase()}`)}
                detailed
                small
              >
                <IconHover icon={renderLocationIcon(session.location)} size={18} />
              </ButtonWithHoverLabel>
            )}
          </span>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <p
            css={css`
              font-size: ${theme.fontSizes.h3};
              font-weight: ${theme.fontWeights.bold};
              color: ${theme.colors.textLight};
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              max-height: 115px;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              margin: 0;
              padding: 0;
            `}
          >
            {session.title}
          </p>
        </div>
        {!!session.speakers && session.speakers.length > 0 && (
          <p
            css={css`
              color: ${theme.colors.textLight};
              font-size: ${theme.fontSizes.s};
              width: 100%;
              margin-top: 0.25em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {session.speakers.length > 1
              ? session.speakers
                  .map<React.ReactNode>((speaker) => <span key={speaker.id}>{speaker.name}</span>)
                  .reduce((prev, curr) => [prev, ', ', curr])
              : session.speakers[0].name}
          </p>
        )}
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: session.description }}
          css={css`
            color: ${theme.colors.textLight};
            font-size: ${theme.fontSizes.s};
            margin-top: 1em;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
            line-height: 16px;
            max-height: 85px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            flex: 1;

            > * {
              margin: 5px 0;
              line-height: 1.2;
            }
          `}
        />
        {!!session.attendees &&
          session.attendees?.length > 0 &&
          sessionStatus === SESSION_STATES.LIVE && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 1em;
              `}
            >
              <AttendeesIndicator size='s' attendees={session.attendees as any} />
            </div>
          )}
      </Section>
      <Section hideBorder>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            {buttons.showJoin && (
              <Button
                onClick={onJoinClick}
                color='secondary'
                label={t('joinSession')}
                size='l'
                disableMinWidth
              />
            )}
            {buttons.showOndemand && (
              <Button
                onClick={onWatchClick}
                color='secondary'
                label={t('watchOnDemand')}
                icon='play'
                iconSize={20}
                size='m'
                disableMinWidth
              />
            )}
          </div>
          {buttons.showMandatory && (
            <ButtonWithHoverLabel mandatory right>
              <IconButton disabled icon='mandatory' size={45} type='scheduled' />
            </ButtonWithHoverLabel>
          )}
          {buttons.showRemoveFromSchedule && (
            <Button
              onClick={onRemoveScheduleClick}
              label={t(scheduleHover ? 'remove' : 'added')}
              icon={scheduleHover ? 'times' : 'checkmark'}
              onMouseEnter={() => setScheduleHover(true)}
              onMouseLeave={() => setScheduleHover(false)}
              iconSize={18}
              disabled={session.mandatory}
              css={css`
                background-color: ${theme.colors.success};
                color: white;
                transition: all 0.2s ease-in-out;
                ${!session.mandatory &&
                `
                  :hover {
                    background-color: ${theme.colors.unsubscribe};
                    color: white;
                  }
                `}

                path {
                  fill: white;
                  stroke: white;
                }
              `}
              size='l'
              disableMinWidth
            />
          )}
          {buttons.showAddToSchedule && (
            <Button
              onClick={onScheduleClick}
              outline
              label={t('addToMySchedule')}
              size='l'
              disableMinWidth
            />
          )}
          {buttons.showReminder && (
            <ButtonWithHoverLabel reminder>
              <IconSwitch
                onChange={onNotifyClick}
                selected={session.reminder}
                icon='bell'
                size={45}
              />
            </ButtonWithHoverLabel>
          )}
        </div>
      </Section>
    </div>
  );
};

export default SessionDetailed;
