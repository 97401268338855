import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import GridView from '@modules/schedule/components/GridView';
import Session, { DISPLAY_TYPE } from '@modules/schedule/components/Session';
import { useSessions } from '@modules/schedule/hooks/useSessions';
import IconSwitch from '@common/components/IconSwitch';
import Loader from '@common/components/Loader';
import { filterSessionsByLocation } from '@modules/event/utils';

import type { ScheduleContext } from '..';

interface DayProps {
  context: React.Context<ScheduleContext>;
}

const Day: React.FC<DayProps> = ({ context }) => {
  const { t } = useTranslation();
  const { selectedDate, viewType, setViewType, scheduled, selectedLocation } = useContext(context);

  const { data, ...api } = useSessions({ page: 1, limit: 99 }, undefined, selectedDate, scheduled);

  const sessions = useMemo(() => data?.items.map((item) => ({ ...item })).flat(), [data?.items]);
  if (api.isFetching && !sessions?.length)
    return (
      <Base>
        <Loader />
      </Base>
    );
  if (!sessions?.length)
    return (
      <Base>
        <Empty>{t('vle_error_no_sessions_found')}</Empty>
      </Base>
    );

  return (
    <div>
      <Base>
        {viewType === 'list' ? (
          filterSessionsByLocation(selectedLocation, sessions)?.map((session) => (
            <Session
              displayType={DISPLAY_TYPE.list}
              session={session}
              key={session.id}
              selectedDate={selectedDate}
            />
          ))
        ) : (
          <GridView
            sessions={filterSessionsByLocation(selectedLocation, sessions)!}
            selectedDate={selectedDate}
          />
        )}
      </Base>
      <Actions>
        <Toggle
          size={40}
          icon='grid'
          type='transparent'
          selected={viewType === 'grid'}
          onChange={() => setViewType('grid')}
        />
        <Toggle
          size={40}
          icon='list'
          type='transparent'
          selected={viewType === 'list'}
          onChange={() => setViewType('list')}
        />
      </Actions>
    </div>
  );
};

export default Day;

const Empty = styled.p`
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${(props) => props.theme.fontSizes.m};
`;

const Base = styled.div<{ minHeight?: number }>`
  margin-top: 60px;
  ${(props) =>
    !!props.minHeight &&
    `
    min-height: ${props.minHeight}px;
  `}
`;

const Actions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Toggle = styled(IconSwitch)`
  margin-left: 1em;
`;
