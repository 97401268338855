/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import Card from '@common/components/Card';
import Chip from '@common/components/Chip';
import Icon from '@common/components/Icon';

import { UserRolePermission } from '@common/transforms/userRole';
import { AnnouncementResource } from '@common/transforms/announcement';

type AnnouncementCardProps = {
  announcement: AnnouncementResource;
  className?: string;
  onClick?: () => void;
};

const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  announcement,
  className,
  onClick,
}) => {
  const nativeOnClick = () => onClick?.();
  const theme = useTheme();

  return (
    <Card
      className={className}
      css={css`
        flex-direction: row;
        padding: 1em;
        background-color: ${theme.colors.eventBackground}AA;
        border-color: ${theme.colors.primary};
        border-width: 2px;
        border-style: solid;
      `}
      onClick={nativeOnClick}
    >
      <Avatar user={announcement.user} size={50} emptyBackground />
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 1.5em;
          position: relative;
        `}
      >
        <Body
          weight='bold'
          color='textLight'
          size='m'
          css={css`
            margin: 0.2em 2em 0.2em 0;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            {announcement.user?.fullName || 'Host'}
            {announcement.user?.role?.permissions.includes(UserRolePermission.HOST_CHAT) &&
              !!announcement.user?.role.name && (
                <Chip
                  size='xxs'
                  css={css`
                    margin-left: 1em;
                    word-break: normal;
                  `}
                >
                  {announcement.user?.role.name}
                </Chip>
              )}
          </div>
        </Body>
        <Body
          size='m'
          color='textLight'
          css={css`
            margin-bottom: 0.5em;
          `}
        >
          {announcement.message}
        </Body>

        <div
          css={css`
            position: absolute;
            top: 0;
            right: 0;
          `}
        >
          <Icon icon='times' size={16} color='#fff' />
        </div>
      </div>
    </Card>
  );
};

export default AnnouncementCard;
