/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ICalendarLink from 'react-icalendar-link';
import queryString from 'query-string';
import dayjs from 'dayjs';

import { SessionResource } from '@common/transforms/session';
import { SpeakerResource } from '@common/transforms/speaker';
import { openExternalWebsite } from '@common/utils/checkUrl';

import i18n from '@common/utils/i18n';
import Button from '@common/components/Button';
import IconButton from '@common/components/IconButton';
import Pill from '@common/components/Pill';
import Icon from '@common/components/Icon';
import Loader from '@common/components/Loader';
import ButtonWithHoverLabel from '@common/components/ButtonWithHoverLabel';
import { mediaQuery } from '@common/styles/mediaQuery';
import { convertDatesToTimeSlot, parseISO } from '@common/utils/time';
import { stripHTML } from '@common/utils/html';
import AttendeesIndicator from '@modules/user/components/AttendeesIndicator';
import { useEvent } from '@modules/event/hooks/useEvent';
import { renderLocationIcon } from '@modules/event/utils';
import {
  useAddScheduledSession,
  useRemoveScheduledSession,
  useUpdateScheduledSession,
} from '@modules/schedule/hooks/useSessions';
import {
  SESSION_STATES,
  getStatus,
  useSessionStatus,
} from '@modules/schedule/hooks/useSessionStatus';
import IconSwitch from '@common/components/IconSwitch';
import Heading from '@common/components/Heading';
import Body from '@common/components/Body';
import IconHover from '@common/components/IconHover';
import { useSessionContext } from '@modules/schedule/hooks/useSessionContext';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';

import Speaker from './SessionDetail/Speaker';

interface SessionDetailsProps {
  session: SessionResource;
  onClose?: () => void;
}

const SessionDetails: React.FC<SessionDetailsProps> = ({ session, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const event = useEvent();

  const { mutate: addScheduledSession } = useAddScheduledSession(session?.id);
  const { mutate: updateScheduledSession } = useUpdateScheduledSession(session?.id);
  const { mutate: removeScheduledSession } = useRemoveScheduledSession(session?.id);
  const { joinSession } = useSessionContext();

  const [scheduleHover, setScheduleHover] = useState(false);
  const [sessionStatus, setSessionStatus] = useState<SESSION_STATES>(getStatus(session));
  const { buttons } = useSessionSettings(session, sessionStatus);

  useSessionStatus(session, setSessionStatus);

  const sessionUrl = `${window.location.href}?show=${session.id}`;
  const ASMLServer = process.env.REACT_APP_ASML_SERVER === 'true';

  // if (
  //   !window.location.href.includes('virtual-live-event.com') &&
  //   session.url?.includes('virtual-live-event.com') &&
  //   sessionUrl
  // ) {
  //   const newHost = new URL(sessionUrl);
  //   newHost.hostname = window.location.hostname;
  //   sessionUrl = newHost.href;
  // }

  // if (session.url && isLocalUrl(session.url)) {
  //   sessionUrl = `${window.location.origin}${session.url}`;
  // }

  const handleJoinClick = () => {
    joinSession(session?.url);
  };

  const handleNotificationsClick = () => {
    if (session?.scheduled) {
      if (session?.reminder) {
        updateScheduledSession(false);
      } else {
        updateScheduledSession(true);
      }
    } else {
      addScheduledSession(true);
    }
  };

  const handleScheduleClick = () => {
    addScheduledSession(true);
  };

  const handleRemoveScheduleClick = () => {
    removeScheduledSession();
  };

  const handleWatchClick = () => {
    joinSession(session?.onDemandUrl);
  };

  const handleCloseClick = () => {
    onClose?.();
  };

  const renderPills = () => {
    if (!session) return null;
    switch (sessionStatus) {
      case SESSION_STATES.LIVE: {
        return (
          <>
            <Pill highlighted icon='live'>
              {t('live.now')}
            </Pill>
            {!!session.stage?.id && <Pill>{session.stage.name}</Pill>}
          </>
        );
      }
      case SESSION_STATES.NOT_STARTED: {
        return (
          <>
            <Pill icon='clock'>
              {convertDatesToTimeSlot(parseISO(session.startsAt), parseISO(session.endsAt))}
            </Pill>
            {!!session.stage?.id && <Pill>{session.stage.name}</Pill>}
          </>
        );
      }
      case SESSION_STATES.PASSED: {
        return <Pill>{session.onDemandUrl ? t('onDemand') : t('finished')}</Pill>;
      }
      default: {
        return !!session.stage?.id && <Pill>{session.stage.name}</Pill>;
      }
    }
  };

  const openGoogleCalendarUrl = () => {
    const startDate = dayjs(session.startsAt).format('YYYYMMDDTHHmm00Z');
    const endDate = dayjs(session.endsAt).format('YYYYMMDDTHHmm00Z');
    const description = stripHTML(session.description);
    openExternalWebsite(
      `https://www.google.com/calendar/render?${queryString.stringify({
        action: 'TEMPLATE',
        text: session.title,
        details: `${description}\n\n${sessionUrl}`,
        location: session.stage?.name,
        dates: `${startDate}/${endDate}`,
      })}`,
    );
  };

  const renderActions = () => {
    if (!session) return null;

    return (
      <div
        css={css`
          display: flex;
          width: 100%;
          flex-direction: column;
          margin-bottom: 0.5rem;
        `}
      >
        <div
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          {buttons.showJoin && (
            <Button
              onClick={handleJoinClick}
              color='secondary'
              label={t('joinSession')}
              size='l'
              disableMinWidth
            />
          )}
          {buttons.showOndemand && (
            <Button
              onClick={handleWatchClick}
              color='secondary'
              label={t('watchOnDemand')}
              icon='play'
              iconSize={20}
              size='m'
              disableMinWidth
            />
          )}
        </div>
        {sessionStatus !== SESSION_STATES.PASSED && (
          <div
            css={css`
              display: flex;
              flex: 1;
              justify-content: space-between;
            `}
          >
            <ButtonRow>
              {buttons.showMandatory && (
                <ButtonWithHoverLabel mandatory detailed>
                  <IconButton disabled icon='mandatory' size={42} type='scheduled' />
                </ButtonWithHoverLabel>
              )}
              {buttons.showAddToSchedule && (
                <ButtonWithHoverLabel labelText={t('addToMySchedule')} detailed big>
                  <Button
                    onClick={handleScheduleClick}
                    color='secondary'
                    label={t('addToMySchedule')}
                    icon='plus'
                    iconSize={20}
                    size='m'
                    disableMinWidth
                  />
                </ButtonWithHoverLabel>
              )}
              {buttons.showRemoveFromSchedule && (
                <ButtonWithHoverLabel labelText={t('removeFromSchedule')} detailed big>
                  <RemoveScheduleButton
                    onClick={handleRemoveScheduleClick}
                    label={t(scheduleHover ? 'remove' : 'added')}
                    icon={scheduleHover ? 'times' : 'checkmark'}
                    onMouseEnter={() => setScheduleHover(true)}
                    onMouseLeave={() => setScheduleHover(false)}
                    iconSize={18}
                    size='m'
                    disableMinWidth
                  />
                </ButtonWithHoverLabel>
              )}
              {buttons.showReminder && (
                <ButtonWithHoverLabel reminder detailed>
                  <BellSwitch
                    onChange={handleNotificationsClick}
                    selected={session.reminder}
                    icon='bell'
                    size={42}
                  />
                </ButtonWithHoverLabel>
              )}
            </ButtonRow>
            <CalendarContainer>
              {t('downloadToCalendar')}:
              <CalendarIcsButton
                event={{
                  title: session.title,
                  description: stripHTML(`${session.description}\n\n${sessionUrl}`, true),
                  startTime: session.startsAt,
                  endTime: session.endsAt,
                  location: session.stage?.name,
                  url: sessionUrl || window.location.href,
                }}
              >
                <Icon icon='calendar' color={theme.colors.textLight} size={30} />
              </CalendarIcsButton>
              {!ASMLServer && (
                <CalendarGoogleButton onClick={() => openGoogleCalendarUrl()}>
                  <Icon icon='google' color={theme.colors.textLight} size={30} />
                </CalendarGoogleButton>
              )}
            </CalendarContainer>
          </div>
        )}
      </div>
    );
  };

  if (!session)
    return (
      <Container>
        <Loader />
      </Container>
    );

  return (
    <Container>
      <Content>
        <Header>
          {session.imageUrl?.length && <Banner src={session.imageUrl} />}
          <HeaderTop hasImage={!!session.imageUrl?.length}>
            <Pills>
              {renderPills()}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <span
                  css={css`
                    margin: 0px 6px;
                  `}
                >
                  •
                </span>
                {renderLocationIcon(session.location) === 'both' ? (
                  <>
                    <ButtonWithHoverLabel
                      labelText={t('attendance_location.onsite')}
                      detailed
                      small
                    >
                      <IconHover icon='pinpoint' size={18} />
                    </ButtonWithHoverLabel>
                    <ButtonWithHoverLabel
                      labelText={t('attendance_location.online')}
                      detailed
                      small
                    >
                      <IconHover icon='laptop' size={18} />
                    </ButtonWithHoverLabel>
                  </>
                ) : (
                  <ButtonWithHoverLabel
                    labelText={t(`attendance_location.${session.location.toLowerCase()}`)}
                    detailed
                    small
                  >
                    <IconHover icon={renderLocationIcon(session.location)} size={18} />
                  </ButtonWithHoverLabel>
                )}
              </div>
            </Pills>

            <CloseButton size={45} icon='times' onClick={handleCloseClick} />
          </HeaderTop>
        </Header>
        <Section>
          <EventDetails>
            <EventDate>
              <div>
                <span>
                  {parseISO(session.startsAt).toLocaleDateString(i18n.language.replace('_', '-'), {
                    weekday: 'short',
                    day: '2-digit',
                    month: 'long',
                  })}
                </span>
              </div>
              <div>
                <span>
                  {parseISO(session.startsAt).toLocaleDateString(i18n.language.replace('_', '-'), {
                    weekday: 'short',
                  })}
                </span>
                <span>
                  {parseISO(session.startsAt).toLocaleDateString(i18n.language.replace('_', '-'), {
                    day: '2-digit',
                  })}
                </span>
                <span>
                  {parseISO(session.startsAt).toLocaleDateString(i18n.language.replace('_', '-'), {
                    month: 'long',
                  })}
                </span>
              </div>
            </EventDate>
            <TitleDetails>
              <Heading size='h2' color='textLight'>
                {session.title}
              </Heading>
              <Body color='textBody'>
                {session?.speakers &&
                  [...session.speakers.map((speaker: SpeakerResource) => speaker.name)].join(' & ')}
              </Body>
            </TitleDetails>
          </EventDetails>
          <Actions>{renderActions()}</Actions>
        </Section>
        <Section hideBorder={!session.speakers?.length}>
          <Body
            css={css`
              color: ${theme.colors.textLight};
              margin-top: 1em;

              > div > * {
                margin: 5px 0;
                line-height: 1.2;
              }
            `}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: session.description }} />
          </Body>
          {!!session.attendees && session.attendees?.length > 0 && (
            <Attendees attendees={session.attendees as any} />
          )}
        </Section>
        {!!session.speakers?.length && (
          <Section hideBorder>
            <Heading size='h3' color='textLight'>
              {t('speakers')}
            </Heading>
            {session.speakers?.map?.((speaker: SpeakerResource) => (
              <Speaker
                key={speaker.id}
                speaker={speaker}
                event={event}
                onClose={handleCloseClick}
              />
            ))}
          </Section>
        )}
      </Content>
    </Container>
  );
};

export default SessionDetails;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 650px;
  overflow: hidden;
  position: relative;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  background-color: ${(props) => props.theme.colors.eventBackground};
  backdrop-filter: blur(${(props) => props.theme.blurs.normal});

  ${(props) =>
    mediaQuery(
      's',
      css`
        max-height: 80%;
        border-radius: ${props.theme.borderRadius.iconButton};
      `,
    )}
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const Banner = styled.img`
  width: 100%;
  height: 30vh;
  max-height: 280px;
  object-fit: cover;
  display: block;
`;

const HeaderTop = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  ${(props) =>
    props.hasImage
      ? `
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  `
      : `
    width: 100%;
    position: relative;
  `}
`;

const Pills = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;

  margin-left: 20px;
  margin-right: 10px;
  margin-top: 20px;

  > div {
    margin-bottom: 5px;
  }
`;

const CloseButton = styled(IconButton)`
  background-color: rgba(0, 0, 0, 0.3);
  flex-basis: 1;
  flex-shrink: 0;

  margin-right: 20px;
  margin-top: 20px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    path {
      stroke: #000;
    }
  }
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${mediaQuery(
    'm',
    css`
      flex-direction: row;
      align-items: center;
    `,
  )}
`;

const EventDate = styled.div`
  > div:first-of-type {
    ${mediaQuery(
      'm',
      css`
        display: none;
      `,
    )}
  }

  > div:nth-of-type(2) {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.textLight};

    ${mediaQuery(
      'm',
      css`
        display: flex;
      `,
    )}

    > span:nth-of-type(1) {
      font-size: ${(props) => props.theme.fontSizes.m};
      line-height: 1.3;
      letter-spacing: 0.16px;
    }

    > span:nth-of-type(2) {
      font-size: ${(props) => props.theme.fontSizes.h2};
      font-weight: ${(props) => props.theme.fontWeights.semibold};
      line-height: 1;
    }

    > span:nth-of-type(3) {
      font-size: ${(props) => props.theme.fontSizes.m};
      line-height: 1.3;
      letter-spacing: 0.16px;
    }
  }
`;

const TitleDetails = styled.div`
  flex: 1;
  margin-top: 20px;

  > h2 {
    margin: 0;
    line-height: 1.19;
  }

  > span {
    margin: 0;
    line-height: 1.5;
    letter-spacing: 0.16px;
  }

  ${mediaQuery(
    'm',
    css`
      margin-top: 0;
      margin-left: 30px;
    `,
  )}
`;

const Actions = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery(
    'm',
    css`
      flex-direction: row;
    `,
  )}
`;

const RemoveScheduleButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.success};
  color: white;
  transition: all 0.2s ease-in-out;
  padding-left: 2.38em;
  padding-right: 2.38em;

  :hover {
    background-color: ${(props) => props.theme.colors.unsubscribe};
    color: white;
  }

  path {
    fill: white;
    stroke: white;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CalendarContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CalendarIcsButton = styled(ICalendarLink)`
  color: ${(props) => props.theme.colors.textLight};
  text-decoration: none;
  line-height: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 0.4rem;

  > svg {
    margin-right: 0.2em;
  }
`;

const CalendarGoogleButton = styled.div`
  color: ${(props) => props.theme.colors.textLight};
  text-decoration: none;
  cursor: pointer;
  line-height: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 0.4rem;

  > svg {
    margin-right: 0.2em;
  }
`;

const BellSwitch = styled(IconSwitch)`
  margin-left: 10px;
  ${mediaQuery(
    'm',
    css`
      margin-right: 20px;
    `,
  )}
`;

const Attendees = styled(AttendeesIndicator)`
  margin-top: 20px;
`;

const Section = styled.div<{ hideBorder?: boolean }>`
  padding: 20px;
  ${(props) =>
    !props.hideBorder &&
    `
        border-bottom: 1px solid ${props.theme.colors.border};
    `}

  ${mediaQuery(
    'm',
    css`
      padding: 30px;
    `,
  )}
`;
