/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Transition } from 'react-transition-group';

import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

interface ButtonWithHoverLabelProps {
  children: React.ReactNode;
  labelText?: string;
  sidebar?: Boolean;
  mandatory?: Boolean;
  reminder?: Boolean;
  right?: Boolean;
  highlighted?: Boolean;
  marginLeft?: Boolean;
  detailed?: Boolean;
  big?: Boolean;
  small?: Boolean;
}

const ButtonWithHoverLabel: React.FC<ButtonWithHoverLabelProps> = ({
  children,
  sidebar = false,
  labelText = '',
  mandatory = false,
  reminder = false,
  highlighted = false,
  right = false,
  marginLeft = false,
  detailed = false,
  small = false,
  big = false,
}) => {
  const [labelOpen, setLabelOpen] = useState(false);
  const { t } = useTranslation();

  const renderText = () => {
    if (mandatory) return t('session_hover_mandatory');
    if (reminder) return t('session_hover_reminder');
    return labelText;
  };

  const Label = right ? IconLabelRight : IconLabel;

  return (
    <ButtonContainer sidebar={sidebar} marginLeft={marginLeft}>
      {!!right && (
        <>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                onMouseEnter: () => setLabelOpen(true),
                onMouseLeave: () => setLabelOpen(false),
              } as any);
            }
            return null;
          })}
        </>
      )}
      <Transition
        in={labelOpen}
        mountOnEnter
        unmountOnExit
        addEndListener={(node, done) => {
          if (labelOpen)
            gsap.fromTo(
              node,
              { scale: 0.96, opacity: 0, y: 10 },
              { scale: 1, y: -5, opacity: 1, duration: 0.2, onComplete: done },
            );
          else
            gsap.fromTo(
              node,
              { scale: 1, opacity: 1, y: 0 },
              { scale: 0.96, y: 15, opacity: 0, duration: 0.2, onComplete: done },
            );
        }}
      >
        <Label
          small={small}
          className='label'
          sidebar={sidebar}
          right={right}
          highlighted={highlighted}
          detailed={detailed}
          big={big}
          isEmpty={!renderText()}
        >
          {renderText()}
        </Label>
      </Transition>
      {!right &&
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onMouseEnter: () => setLabelOpen(true),
              onMouseLeave: () => setLabelOpen(false),
            } as any);
          }
          return null;
        })}
    </ButtonContainer>
  );
};

export default ButtonWithHoverLabel;

const IconLabel = styled.div<{
  sidebar: Boolean;
  right: Boolean;
  highlighted: Boolean;
  detailed: Boolean;
  isEmpty: Boolean;
  small: Boolean;
  big: Boolean;
}>`
  display: block;
  transform: scale(0.96) translateY(100px) translateZ(99999px);
  color: ${(props) => (props.sidebar ? 'white' : 'unset')};
  opacity: 0;
  z-index: 9999;
  width: max-content;
  background: rgba(44, 44, 44, 0.781);
  border-radius: 30px;
  padding: ${(props) => (props.isEmpty ? null : '1em 1.5em')};
  position: absolute;
  right: ${(props) =>
    props.detailed ? null : props.small ? '30px' : props.big ? '200px' : '60px'};
  left: ${(props) =>
    props.highlighted
      ? '0px'
      : props.detailed
      ? props.small
        ? '30px'
        : props.big
        ? '200px'
        : '60px'
      : null};
  top: ${(props) => (props.highlighted ? '65px' : null)};
  pointer-events: none;
`;

const IconLabelRight = styled(IconLabel)`
  left: ${(props) => (props.detailed ? null : props.small ? '30px' : props.big ? '200px' : '60px')};
  right: ${(props) =>
    props.highlighted
      ? '0px'
      : props.detailed
      ? props.small
        ? '30px'
        : props.big
        ? '200px'
        : '60px'
      : null};
`;

const ButtonContainer = styled.div<{ sidebar: Boolean; marginLeft: Boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: ${(props) => (props.sidebar ? '1rem' : '0')};
  margin-left: ${(props) => (props.marginLeft ? '0.5em' : '0')};
  position: relative;
`;
