/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { Transition } from 'react-transition-group';

import { gsap } from 'gsap';

import Body from '@common/components/Body';
import Heading from '@common/components/Heading';
import Avatar from '@modules/user/components/Avatar';
import TagCloud from '@modules/event/components/TagCloud';
import Icon from '@common/components/Icon';
import Divider from '@common/components/Divider';

import { mediaQuery } from '@common/styles/mediaQuery';
import { useCustomFields } from '@modules/event/hooks/useCustomFields';
import { CallStatus } from '@common/transforms/user';
import { CustomFieldType } from '@common/transforms/customField';
import { openExternalWebsite } from '@common/utils/checkUrl';

import Button from '@common/components/Button';
import RoundButton from '@common/components/RoundButton';

import { useAppSelector } from '@common/store';

import { UserRolePermission } from '@common/transforms/userRole';

import { useUser } from '../hooks/useUsers';

type ProfileProps = {
  userId?: string;
  onCloseClick?: () => void;
  openModal?: boolean;
  onOpenChat?: (participantId: string) => void;
  onCallUser?: (participantId: string) => void;
};

const filteredFields = ['videolink', 'linkedin', 'jobtitle', 'company'];

const Profile: React.FC<ProfileProps> = ({
  userId,
  onCloseClick,
  onOpenChat,
  onCallUser,
  openModal,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: user } = useUser(userId);
  const currentUser = useAppSelector((state) => state.auth.user);

  const { data: customFields } = useCustomFields();
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const userCustomFieldsOrder: string[] = [];
  customFields?.map((field) => userCustomFieldsOrder.push(field.key));

  const mapOrder = (array: any[], order: string | any[], key: string) => {
    array.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
      const A = a[key as string];
      const B = b[key as string];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      }
      return -1;
    });
    return array;
  };

  const orderedCustomFields =
    user && userCustomFieldsOrder.length > 0
      ? mapOrder(user.fields, userCustomFieldsOrder, 'key')
      : user?.fields;

  const filteredCustomFields =
    orderedCustomFields &&
    orderedCustomFields
      .filter((field) => !filteredFields.includes(field.key))
      .map((v) => {
        const field = customFields?.find((v1) => v1.key === v.key);
        return {
          ...v,
          value:
            field?.type === CustomFieldType.DROPDOWN
              ? field.options.find((o) => o.key === v.value)?.label ?? v.value
              : v.value,
        };
      });

  return (
    <Transition
      in={openModal}
      mountOnEnter
      unmountOnExit
      addEndListener={(node, done) => {
        if (openModal)
          gsap.fromTo(
            node,
            { opacity: 0, scale: 0.96 },
            {
              opacity: 1,
              duration: 0.4,
              scale: 1,
              ease: 'power4.inOut',
              onComplete: done,
            },
          );
        else
          gsap.fromTo(
            node,
            { opacity: 1, scale: 1 },
            {
              opacity: 0,
              duration: 0.4,
              scale: 0.96,
              ease: 'power4.inOut',
              onComplete: done,
            },
          );
      }}
    >
      <div
        ref={overlayRef}
        css={css`
          position: fixed;
          opacity: 0;
          transform: scale(0.96);
          left: 0;
          top: 0;
          height: 100vh;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 123123123;
          cursor: pointer;
        `}
        onClick={(e) => e.target === overlayRef.current && onCloseClick?.()}
      >
        <div
          css={css`
            cursor: default;
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            min-height: 100vh;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 1em;
            padding-top: 4em;
            box-sizing: border-box;
            backdrop-filter: blur(${theme.blurs.normal});
            @supports not (
              (backdrop-filter: blur(${theme.blurs.normal})) or
                (-webkit-backdrop-filter: blur(${theme.blurs.normal}))
            ) {
              background-color: ${theme.colors.eventBackground};
              border-color: #feddc6;
              border-width: 3px;
              border-style: solid;
            }
            ${mediaQuery(
              's',
              css`
                margin: 2em 0;
                border-radius: ${theme.borderRadius.normal};
                width: 90vw;
                max-width: 620px;
                padding: 2em;
                min-height: auto;
              `,
            )}
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 1em;
              right: 1em;
              cursor: pointer;
              ${mediaQuery(
                's',
                css`
                  top: 2em;
                  right: 2em;
                `,
              )}
            `}
            onClick={() => onCloseClick?.()}
          >
            <Icon icon='times' size={16} color={theme.colors.textLight} />
          </div>
          <Avatar
            user={user}
            size={128}
            css={css`
              margin-bottom: 32px;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-direction: column;
              padding: 0 2em;
              margin-bottom: 24px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 0 2em;
                margin-bottom: 12px;
              `}
            >
              <Heading
                size='h2'
                color='textLight'
                css={css`
                  text-align: center;
                  margin-bottom: 0;
                `}
              >
                {user?.fullName}
              </Heading>
              <Body color='primary'>{user?.function}</Body>
              <Body color='primary'>{user?.company}</Body>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                margin-top: 0.5em;
                > * {
                  margin-right: 0.5em;
                }
              `}
            >
              {user?.linkedIn && (
                <RoundButton size='s' onClick={() => openExternalWebsite(user?.linkedIn)}>
                  <Icon icon='linkedin' color='#fff' size={14} />
                </RoundButton>
              )}
              {user?.videoLink && (
                <RoundButton size='s' onClick={() => openExternalWebsite(user?.videoLink)}>
                  <Icon icon='video-call' color='#fff' size={16} />
                </RoundButton>
              )}
            </div>

            {filteredCustomFields && filteredCustomFields.length > 0 && (
              <table
                css={css`
                  margin: 1em 0;
                `}
              >
                <tbody>
                  {filteredCustomFields.map((field) => (
                    <tr key={field.customFieldId}>
                      <td align='right'>
                        <Body
                          size='s'
                          weight='bold'
                          color='textLight'
                          css={css`
                            text-align: right;
                            margin-right: 1em;
                          `}
                        >
                          {field.label}
                        </Body>
                      </td>
                      <td>
                        <Body size='s' color='textLight'>
                          {field.value}
                        </Body>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {onOpenChat &&
              (user?.role?.permissions ?? []).includes(UserRolePermission.CREATE_PRIVATE_CHAT) &&
              (currentUser?.role?.permissions ?? []).includes(
                UserRolePermission.CREATE_PRIVATE_CHAT,
              ) && (
                <Button
                  label={t('startchat_button')}
                  size='xs'
                  css={css`
                    flex: 1;
                    margin-top: 1em;
                    ${mediaQuery(
                      's',
                      css`
                        flex: 0;
                      `,
                    )}
                  `}
                  onClick={() => {
                    if (user?.id) onOpenChat(user.id);
                    onCloseClick?.();
                  }}
                />
              )}
            {user?.isOnline &&
              user?.callStatus === CallStatus.AVAILABLE &&
              (user?.role?.permissions ?? []).includes(
                UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS,
              ) &&
              (currentUser?.role?.permissions ?? []).includes(
                UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS,
              ) &&
              onCallUser && (
                <Button
                  label={t('startcall_button')}
                  size='xs'
                  css={css`
                    flex: 1;
                    margin-top: 1em;
                    ${mediaQuery(
                      's',
                      css`
                        flex: 0;
                      `,
                    )}
                  `}
                  onClick={() => {
                    if (user?.id) onCallUser(user.id);
                    onCloseClick?.();
                  }}
                />
              )}
          </div>

          {!!user?.tags.length && (
            <>
              <div
                css={css`
                  width: 110%;
                  height: 0;
                  opacity: 0.1;
                  margin-top: 1em;
                  border: solid 1px ${theme.colors.textLight};
                `}
              />
              <Divider />
              <div
                css={css`
                  width: 100%;
                  margin-top: 30px;
                  padding: 0 2em;
                `}
              >
                <Heading size='h3' color='textLight' align='center'>
                  {t('tags_title')}
                </Heading>
                <div
                  css={css`
                    margin-top: 20px;
                  `}
                >
                  <TagCloud tags={user?.tags} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Transition>
  );
};

export default Profile;
