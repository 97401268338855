/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Icon as IconType } from '@typing/icons';

import Icon from '@common/components/Icon';
import { useAppSelector } from '@common/store';

interface IconSwitchProps {
  icon: string;
  size?: number;
  onChange: (value: boolean) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  round?: boolean;
  iconProps?: Partial<IconType>;
  type?: 'default' | 'defaultDark' | 'transparent' | 'transparentDark' | 'schedule' | 'scheduled';
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  color?: string;
}

const IconSwitch: React.FC<IconSwitchProps> = ({
  round = false,
  icon,
  iconProps,
  size,
  onChange,
  type = 'default',
  selected = false,
  color,
  ...rest
}) => {
  const theme = useTheme();
  const elementFullScreen = useAppSelector((state) => state.fullscreen.isElementFullScreen);

  const nativeOnClick = (ev: any) => {
    onChange?.(!selected);
    ev.stopPropagation();
  };

  const hoverBackground =
    theme.colors.buttons[type].backgroundHover ?? theme.colors.buttons[type].background;
  return (
    <button
      {...rest}
      onClick={nativeOnClick}
      css={css`
        width: ${size ?? 24}px;
        height: ${size ?? 24}px;
        max-width: ${size ?? 24}px;
        max-height: ${size ?? 24}px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${rest.disabled
          ? theme.colors.buttons[type].backgroundDisabled ?? theme.colors.buttons[type].background
          : selected
          ? color
            ? color === '#000'
              ? theme.colors.buttons.transparentDark.backgroundFocus
              : hoverBackground
            : theme.colors.buttons[type].backgroundFocus ?? hoverBackground
          : theme.colors.buttons[type].background};

        ${!elementFullScreen && `backdrop-filter: blur(${theme.blurs.normal});`}
        border: 0;
        cursor: pointer;
        border-radius: ${round ? '100px' : theme.borderRadius.iconButton};
        transition: 0.2s all ease-in-out;
        position: relative;

        color: ${rest.disabled
          ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
          : selected
          ? color ??
            theme.colors.buttons[type].colorFocus ??
            theme.colors.buttons[type].colorHover ??
            theme.colors.buttons[type].color
          : color ?? theme.colors.buttons[type].color};

        path,
        circle,
        rect {
          fill: ${rest.disabled
            ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
            : selected
            ? color ??
              theme.colors.buttons[type].colorFocus ??
              theme.colors.buttons[type].colorHover ??
              theme.colors.buttons[type].color
            : color ?? theme.colors.buttons[type].color};
          stroke: ${rest.disabled
            ? color ?? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
            : selected
            ? theme.colors.buttons[type].colorFocus ??
              theme.colors.buttons[type].colorHover ??
              theme.colors.buttons[type].color
            : color ?? theme.colors.buttons[type].color};
        }

        ${!selected &&
        `
          :hover {
            background-color: ${
              rest.disabled
                ? theme.colors.buttons[type].backgroundDisabled ??
                  theme.colors.buttons[type].background
                : color
                ? color === '#000'
                  ? theme.colors.buttons.transparentDark.backgroundFocus
                  : hoverBackground
                : hoverBackground
            };
            color: ${
              rest.disabled
                ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
                : color
                ? color === '#000'
                  ? color
                  : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
                : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
            };
            
            path,
            circle,
            rect {
              fill: ${
                rest.disabled
                  ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
                  : color
                  ? color === '#000'
                    ? color
                    : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
                  : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
              };
              stroke: ${
                rest.disabled
                  ? theme.colors.buttons[type].colorDisabled ?? theme.colors.buttons[type].color
                  : color
                  ? color === '#000'
                    ? color
                    : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
                  : theme.colors.buttons[type].colorHover ?? theme.colors.buttons[type].color
              };
            }
          }
        `}
      `}
    >
      <IconWrapper>
        <Icon
          icon={icon}
          color={
            color ||
            (selected
              ? theme.colors.buttons[type].colorFocus ??
                theme.colors.buttons[type].colorHover ??
                theme.colors.buttons[type].color
              : theme.colors.buttons[type].color)
          }
          size={(size ?? 24) - 20}
          {...iconProps}
        />
      </IconWrapper>
    </button>
  );
};

export default IconSwitch;

const IconWrapper = styled.div<{ hidden?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: opacity 0.15s ease-in-out;
  will-change: opacity;
`;
